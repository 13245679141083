import Alpine from "alpinejs"
import { load, store } from "../localStorage"
import validate from "../validations"
import pipeHeader from "./pipeHeader"
import optionsForm from "./optionsForm"
import heightForm from "./heightForm"
import weightForm from "./weightForm"
import targetWeightForm from "./targetWeightForm"
import importantEventDate from "./importantEventDate"
import timer from "./timer"
import checkout from "./checkout"
import primer from "./primer"
import goalPace from "./goalPace"
import fasterProgress from "./fasterProgress"
import walking from "./walking"
import email from "./email"
import results from "./results"
import circularLoading from "./circularLoading"
import glassesOfWater from "./glassesOfWater"
import sliderInput from "./sliderInput"
import cookieConsent from "../../../../../lib/common/js/components/cookieConsent"
import basicThankYouView from "./basicThankYouView"
import checkoutPlanSelection from "./checkoutPlanSelection"

// Upsell imports

import premiumCheckout from "./premiumCheckout"
import premiumOffer from "./premiumOffer"
import shoppingListOffer from "./shoppingListOffer"
import shoppingListCheckout from "./shoppingListCheckout"
import chatCheckout from "./chatCheckout"
import chatOffer from "./chatOffer"
import bundleCheckout from "./bundleCheckout"
import bundleOffer from "./bundleOffer"
import upsellsThankYou from "./upsellsThankYou"

const VIEW_STORAGE_KEY = "view"
const GLOBAL_STORAGE_KEY = "data"
const INVALID_DOMAIN_CODE = "invalid_domain"
const EMAIL_VALIDATION_CODE = "email_verification_failed"

const basicView = ({ nextStepPath, formFields, redirectIfWeightGainUrl, isReducerNeeded }) => ({
  isSubmitAllowed: false,
  showSpinner: false,
  formValues: {},
  errors: {},
  showFetchError: false,
  segmentActionName: "",
  statusToTrack: null,
  store: Alpine.store("data"),
  loading: false,

  // Start: U-series code
  commonInit() {
    window.addEventListener("pageshow", (event) => {
      if (event.persisted) {
        this.store.reload()
        this.loading = false
        this.showSpinner = false
        this.initFormValues(formFields) // Adjust the form values after the page reload

        setTimeout(() => {
          this.initActions() // WARNING: initActions() called here!
        }, 1)
      }
    })

    setTimeout(() => {
      this.initActions()
    }, 1)

    this.initFormValues(formFields)

    if (redirectIfWeightGainUrl) {
      this.redirectIfWeightGain(redirectIfWeightGainUrl)
    }
  },

  init() {
    this.commonInit()
  },

  initActions() {
    if (isReducerNeeded) {
      this.formValues = Object.keys(this.formValues).reduce(
        (formValues, key) => ({
          ...formValues,
          [key]: this.store.values[key],
        }),
        {},
      )
    }

    if (this.onInit) this.onInit()

    this.store.trackViewAndMaybeStatus(this.statusToTrack)

    requestAnimationFrame(this.focusField)
  },

  focusField() {
    const field = document.querySelector("[autofocus]")

    if (field) field.focus()
  },

  getNextStepPath(withToken = false, nextPath) {
    if (!withToken || !this.store.values.token) return nextPath

    return `${nextStepPath}#stored-token=${this.store.values.token}`
  },

  scrollToFirstError() {
    document
      .querySelector("[data-error]")
      .scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
  },

  handleApiError(e) {
    this.errors = { [e.path]: e.code }
  },

  isEmailValidationError() {
    const errors = this.errors
    if (errors) {
      if (errors.email === INVALID_DOMAIN_CODE || errors.email === EMAIL_VALIDATION_CODE) {
        return true
      }
    }

    return false
  },

  // End: U-series code

  initFormValues(formFields) {
    if (!formFields) return

    const viewData = load(VIEW_STORAGE_KEY)
    const globalData = load(GLOBAL_STORAGE_KEY)

    const isActiveErrors = viewData?.errors && viewData.path === window.location.pathname

    if (isActiveErrors) {
      this.formValues = viewData.formValues
      this.errors = viewData.errors
    } else {
      formFields.map((field) => {
        const value = globalData.values[field]

        this.formValues[field] = value
      })
    }

    this.setSubmitAllowed(this.formValues, formFields)
  },

  isSelected(name, value) {
    return Boolean(this.formValues[name]?.includes(value))
  },

  isMetric() {
    return this.store.values.measurementSystem === "metric"
  },

  isImperial() {
    return this.store.values.measurementSystem === "imperial"
  },

  setMeasurementSystem(value) {
    if (!value) return

    this.store.values.measurementSystem = value
  },

  update(field, value) {
    this.formValues[field] = value
    this.submit()
  },

  updateAndRedirect(field, value, redirectPath) {
    this.formValues[field] = value
    this.submit({ redirectPath })
  },

  handleInput($event, field) {
    this.formValues[field] = $event.target.value && $event.target.value.trim()

    this.errors[field] = null

    this.setSubmitAllowed(this.formValues, formFields)
  },

  handleNumericInput($event, field) {
    $event.target.value = $event.target.value.replace(/[^\d]/g, "")

    this.formValues[field] = $event.target.value && Number($event.target.value)

    this.errors[field] = null

    this.setSubmitAllowed(this.formValues, formFields)
  },

  getRequiredFields(formFields) {
    // Treat all formFields as required by default
    if (Array.isArray(formFields)) {
      return formFields
    }

    // Provide the ability to pass the extended config for the formFields
    return Object.keys(formFields).map((key) => {
      if (formFields[key].required) {
        return key
      }
    })
  },

  setSubmitAllowed(formValues, formFields) {
    this.validateRequired(formValues, formFields)
  },

  validateRequired(formValues, formFields) {
    const requiredFields = this.getRequiredFields(formFields)
    const allRequiredPresent = requiredFields.every((value) => {
      const formValue = formValues[value]

      if (Array.isArray(formValue)) return !!formValue.length

      return formValue && formValue !== 0
    })

    this.isSubmitAllowed = allRequiredPresent
  },

  resetErrors() {
    this.errors = {}
  },

  processFormValues() {
    return this.formValues
  },

  storeViewData(data) {
    store(VIEW_STORAGE_KEY, {
      path: window.location.pathname,
      ...data,
    })
  },

  async submit({ push, redirectPath, withToken } = {}) {
    if (this.store.preloadPromise) {
      this.loading = true
      await this.store.preloadPromise
    }

    const nextPath = redirectPath || nextStepPath
    const formValues = this.processFormValues()

    const { hasErrors, errors } = validate(formValues, this.store)

    if (hasErrors) {
      window.analytics.track(window.getEventName("Invalid Form Submitted"))

      this.storeViewData({ errors, formValues: this.formValues })

      this.errors = { ...this.errors, ...errors }
    } else {
      this.showSpinner = true
      this.isSubmitAllowed = false
      this.loading = true
      this.errors = {}
      this.store.values = { ...this.store.values, ...formValues }

      this.storeViewData(null)

      if (!push || (await this.store.push(false, null, this.handleApiError.bind(this)))) {
        window.location = this.getNextStepPath(withToken, nextPath)
      } else {
        const isEmailError = this.isEmailValidationError()
        if (this.store.emailValidationError || isEmailError) {
          this.store.values.email = ""
          this.errors.email = true
        }
        this.showFetchError = true
        this.showSpinner = false
        this.loading = false
      }
    }
  },

  // The edge case handling, when user's target weight is bigger than the current weight
  redirectIfWeightGain(url) {
    const isMetric = this.isMetric()

    if (isMetric) {
      if (this.store.values.targetWeightKg >= this.store.values.weightKg) {
        window.location = url
        return
      }
    } else {
      if (this.store.values.targetWeightLbs >= this.store.values.weightLbs) {
        window.location = url
        return
      }
    }

    // The component's content may be hidden before init for the better UI
    this.initContentHidden = false
  },
})

export default [
  { name: "view", data: basicView },
  { name: "cookieConsent", data: cookieConsent },
  { name: "pipeHeader", data: pipeHeader },
  { name: "optionsForm", data: optionsForm(basicView) },
  { name: "heightForm", data: heightForm(basicView) },
  { name: "weightForm", data: weightForm(basicView) },
  { name: "targetWeightForm", data: targetWeightForm(basicView) },
  { name: "goalPace", data: goalPace(basicView) },
  { name: "fasterProgress", data: fasterProgress(basicView) },
  { name: "walking", data: walking(basicView) },
  { name: "glassesOfWater", data: glassesOfWater(basicView) },
  { name: "importantEventDate", data: importantEventDate(basicView) },
  { name: "email", data: email(basicView) },
  { name: "sliderInput", data: sliderInput },
  { name: "circularLoading", data: circularLoading },
  { name: "results", data: results },
  { name: "timer", data: timer },
  { name: "checkout", data: checkout(basicView) },
  { name: "primer", data: primer },
  { name: "basicThankYouView", data: basicThankYouView(basicView) },
  { name: "premiumOffer", data: premiumOffer(basicView) },
  { name: "premiumCheckout", data: premiumCheckout },
  { name: "shoppingListOffer", data: shoppingListOffer(basicView) },
  { name: "shoppingListCheckout", data: shoppingListCheckout },
  { name: "chatOffer", data: chatOffer(basicView) },
  { name: "chatCheckout", data: chatCheckout },
  { name: "bundleOffer", data: bundleOffer(basicView) },
  { name: "bundleCheckout", data: bundleCheckout },
  { name: "upsellsThankYou", data: upsellsThankYou(basicView) },
  { name: "checkoutPlanSelection", data: checkoutPlanSelection(basicView) },
]
