import { load, store, getStoreByKeyValue } from "./localStorage"
import { omit, isNil } from "lodash"

const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462
const INCH_IN_CM = 2.54
const CM_IN_FT = 30.48

export const PRICE_TYPES = {
  TRIAL: "trial",
  CHANCE_TRIAL: "chance_trial",
  STANDARD: "standard",
  CHANCE: "chance",
}
export const GOALS = {
  WEIGHT_LOSS: "Lose weight",
  LEAN_AND_LOSE: "Get leaner & lose extra pounds",
  MUSCLE_GAIN: "Gain muscle",
  MAINTAIN: "Develop healthy habits",
}
export const kgToLbs = (kg) => Math.round(kg / KGS_IN_LBS)
export const lbsToKg = (lbs) => Math.round(lbs / LBS_IN_KG)
export const cmToFtIn = (cm) => {
  const totalInch = cm / INCH_IN_CM
  const feet = Math.floor(totalInch / 12)
  const inch = totalInch - 12 * feet

  return { heightFt: Math.round(feet), heightIn: Math.round(inch) }
}
export const ftInToCm = ({ ft, inch }) => {
  const heightCm = Math.ceil(ft * CM_IN_FT + inch * INCH_IN_CM)

  return heightCm
}
export const round = Math.round

const getDefaultOffer = () => {
  const basicOffers = pipelineConfig.offers.filter(
    (offer) => offer.type && offer.type.toLowerCase() === "basic",
  )

  // If single offer pipe
  if (basicOffers.length === 1) return basicOffers[0]

  // If multiple offers pipe
  return basicOffers.find((offer) => offer.customData.default_select === "true")
}

function initialState() {
  return {
    usePaymentsApi: true,
    token: null,
    domain: null,
    measurementSystem: pipelineConfig.measurementSystem,
    weightKg: null,
    weightLbs: null,
    age: null,
    heightCm: null,
    heightFt: null,
    heightIn: null,
    gender: null,
    firstName: null,
    goal: GOALS.WEIGHT_LOSS,
    targetWeightKg: null,
    targetWeightLbs: null,
    goalPaceKg: null,
    goalPaceLbs: null,
    sleep: null,
    diets: null,
    glassesOfWater: null,
    importantEvent: null,
    importantEventDate: null,
    medication: null,
    deseases: null,
    marketingAgreed: null,
    weightLossReceive: null,
    email: null,
    physicallyActive: null,
    ageRange: null,
    yourGoal: null,
    bodyType: null,
    familiarWithFasting: null,
    hungerTime: null,
    breakfastTime: null,
    lunchTime: null,
    dinnerTime: null,
    cookies: null,
    segmentUserId: null,
    fastingWeekends: null,
    mealPrep: null,
    willToLoseWeight: null,
    workingHours: null,
    describeJob: null,
    exitIntent: false,
    showDiscountModal: false,
    isAbandonedCartViewed: false,
    activeOffer: getDefaultOffer(),
    purchasedBasicOffer: null,
    skippedUpsellOffers: [],
    paymentMethod: null,
    paymentSuccessUrl: "",
    paymentFailUrl: "",
    selectedChatNutrionist: null,
  }
}

export const LOCAL_STORAGE_KEY = "data"

export default {
  init() {
    this.values = initialState()

    this.reload()

    this.storeAnalytics()

    Alpine.effect(() => {
      this.writeToLocalStorage()
    })
  },

  reload() {
    const data = this.readFromLocalStorage()

    if (data?.values) {
      this.values = data.values
    }
  },

  async trackViewAndMaybeStatus(status) {
    if (!status) return // Because of order update on each step. WE only update once status is changed or manually pushed data on click
    if (status) this.values.status = status
    this.push(true)
  },

  storeAnalytics() {
    const analyticsData = {}

    analyticsData.domain = document.location.host

    // Add cookies - _ga, _gid, _fbc, _fbp, ...
    document.cookie.split("; ").forEach((item) => {
      const [key, value] = item.split("=")
      analyticsData[key] = value
    })

    // Add search params - fbclid, utm_*, ...
    const url = new URL(document.location)
    url.searchParams.forEach((value, key) => {
      analyticsData[key] = value
    })

    // Add FraudNet data
    analyticsData["fraud_net_session_id"] = localStorage.getItem("fraud_net_session_id") || ""

    delete analyticsData[""]

    this.values = { ...this.values, ...analyticsData }
  },

  getGA4SessionId() {
    try {
      const cookies = document.cookie
        .split("; ")
        .find((item) => item.includes("_ga_"))
        .split("=")[1]
      return cookies.split(".")[2]
    } catch (e) {
      return
    }
  },

  getProgressWidth(activeStepNumber) {
    if (activeStepNumber < 12) {
      return (activeStepNumber * 2.7).toFixed(2)
    }

    if (activeStepNumber < 19) {
      return (activeStepNumber * 3.53).toFixed(2)
    }

    if (activeStepNumber > 18 && activeStepNumber < 22) {
      return "85"
    }

    if (activeStepNumber == 22) {
      return "100"
    }
  },

  handleCheckoutRedirect(nextStepPath) {
    this.values.showDiscountModal = true
    this.values.exitIntent = true
    this.clearPrimerSession()

    if (this.values.token)
      return window.location.replace(`${nextStepPath}#stored-token=${this.values.token}`)

    window.location.replace(nextStepPath)
  },

  async setActiveOffer(changedOffer) {
    const newActiveOffer = pipelineConfig.offers.find(
      (offer) => offer.brandOfferId === changedOffer.brandOfferId,
    )
    if (!newActiveOffer) return

    this.values.activeOffer = newActiveOffer
    this.usePreloadedPrimerSession(newActiveOffer)
  },

  // Push data store to backend API
  async push(withPath = false, skippedOfferId = null, onError = null) {
    const apiUrl = pipelineConfig.orderApiUrl
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...this.values,
        ...(withPath && { path: window.location.pathname }),
        ...(skippedOfferId && { skippedOfferId }),
      }),
    }

    if (this.values.token) {
      options.method = "PATCH"
      options.headers["x-token"] = this.values.token
    }

    try {
      const res = await fetch(apiUrl, options)
      const data = await res.json()
      if (data.token) {
        this.values.token = data.token
      }

      if (withPath || skippedOfferId) return

      if (res.ok) {
        this.error = undefined

        Object.entries(data).forEach(([key, value]) => {
          if (key !== "path") this.values[key] = value
        })
      } else {
        if (onError) onError(data)
        this.error = data.error
      }
    } catch (error) {
      if (withPath) return

      this.error = error
    }

    return !this.error
  },

  readFromLocalStorage() {
    return load(LOCAL_STORAGE_KEY)
  },

  writeToLocalStorage() {
    store(LOCAL_STORAGE_KEY, { values: this.values })
  },

  // Fetch data from backend API
  async pull(waitForLoginUrl = false, ignoreOffer = false) {
    let omitKeys = ["path", "showDiscountModal"]
    if (ignoreOffer) {
      omitKeys.push("activeOffer", "primerSession", "preloadedPrimerSessions")
    }
    const searchParams = new URLSearchParams()
    if (waitForLoginUrl) {
      searchParams.append("waitForLoginUrl", "true")
    }
    if (this.values.token) {
      try {
        const res = await fetch(`${pipelineConfig.orderApiUrl}?${searchParams.toString()}`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            "x-token": this.values.token,
          },
        })
        let data = await res.json()
        data = omit(data, omitKeys)

        if (res.ok) {
          this.error = undefined

          Object.entries(data).forEach(([key, value]) => {
            this.values[key] = value
          })
        } else {
          this.error = data.error
        }

        this.values = initialState()
        Object.entries(data).forEach(([key, value]) => {
          this.values[key] = value
        })
        this.clearPrimerSession()
        this.writeToLocalStorage()
      } catch (error) {
        this.error = error
      }
    }
  },

  get normalizedHeight() {
    if (this.isMetric) {
      return this.values.heightCm
    } else {
      return this.values.heightFt * 30.48 + this.values.heightIn * 2.54
    }
  },

  get normalizedWeight() {
    if (this.isMetric) {
      return this.values.weightKg
    } else {
      return this.values.weightLbs * KGS_IN_LBS
    }
  },

  get isFemale() {
    return this.values.gender === "female"
  },

  get isMale() {
    return this.values.gender === "male"
  },

  gender(value) {
    this.values.gender = value
  },

  get weightUnits() {
    const kgTranslated = weightTranslations["kg"]
    const lbsTranslated = weightTranslations["lbs"]

    return this.isMetric ? kgTranslated : lbsTranslated
  },

  get isMetric() {
    return this.values.measurementSystem === "metric"
  },

  get isImperial() {
    return this.values.measurementSystem === "imperial"
  },

  get loseWeight() {
    return this.values.goal === "Lose weight"
  },

  get bmi() {
    return round(this.normalizedWeight / (this.normalizedHeight / 100.0) ** 2)
  },

  get overWeight() {
    return this.normalizedWeight - this.idealWeight
  },

  get idealWeight() {
    return Math.max(round((this.normalizedHeight ** 2 * 21.1) / 10000), 30)
  },

  get idealWeightRange() {
    const minBMI = 18.5
    const maxBMI = 27
    const lowestNormalizedWeightValue = 30

    let min = minBMI * Math.pow(this.normalizedHeight / 100, 2)
    let max = maxBMI * Math.pow(this.normalizedHeight / 100, 2)

    if (min >= max) {
      min = max - 1
    }

    min = Math.max(round(min), lowestNormalizedWeightValue)
    max = Math.max(round(max), lowestNormalizedWeightValue)

    if (this.isImperial) {
      min = kgToLbs(min)
      max = kgToLbs(max)
    }

    min = round(min)
    max = round(max)

    return Array.from({ length: max - min + 1 }, (_, i) => i + min)
  },

  get targetWeight() {
    return this.isMetric ? this.values.targetWeightKg : this.values.targetWeightLbs
  },

  formattedHeight() {
    const { heightCm, heightFt, heightIn } = this.values

    if (this.isImperial) {
      return pipelineConfig.dir === "rtl"
        ? `in${heightIn} ft${heightFt}`
        : `${heightFt}ft ${heightIn}in`
    } else {
      return pipelineConfig.dir === "rtl" ? `cm${heightCm}` : `${heightCm}cm`
    }
  },

  formatWeight(value) {
    return `${value}${this.weightUnits}`
  },

  formattedWeight() {
    return this.formatWeight(this.isMetric ? this.values.weightKg : this.values.weightLbs)
  },

  formattedIdealWeight(rawValue = false) {
    if (rawValue) {
      return this.isMetric ? this.idealWeight : round(kgToLbs(this.idealWeight))
    } else {
      return this.formatWeight(this.isMetric ? this.idealWeight : round(kgToLbs(this.idealWeight)))
    }
  },

  selectedWeight() {
    if (this.targetWeight && this.idealWeightRange.includes(this.targetWeight)) {
      return this.targetWeight
    } else {
      return this.formattedIdealWeight(true)
    }
  },

  formattedMinIdealWeight() {
    return this.formatWeight(this.idealWeightRange[0])
  },

  formattedMaxIdealWeight() {
    return this.formatWeight(this.idealWeightRange.slice(-1)[0])
  },

  formattedWeightToLose() {
    const { weightKg, targetWeightKg, weightLbs, targetWeightLbs } = this.values

    return this.formatWeight(
      this.isMetric ? weightKg - targetWeightKg : weightLbs - targetWeightLbs,
    )
  },

  formattedWeightGoal() {
    return this.formatWeight(
      this.isMetric ? this.values.targetWeightKg : this.values.targetWeightLbs,
    )
  },

  usePreloadedPrimerSession(offer) {
    if (!this.values.preloadedPrimerSessions) return

    this.values.primerSession = this.values.preloadedPrimerSessions[offer.brandOfferId]
  },

  async preloadPrimerSessions() {
    const basicOffers = pipelineConfig.offers.filter(
      (offer) => offer.type && offer.type.toLowerCase() === "basic",
    )

    if (basicOffers.length !== 1) return

    this.preloadPromise = Promise.all(
      basicOffers.map(async (basicOffer) => {
        const primerSession = await this.callPaymentsApi(basicOffer, true)

        return [basicOffer.brandOfferId, primerSession]
      }),
    )

    const result = await this.preloadPromise

    try {
      this.values.preloadedPrimerSessions = result.reduce(
        (acc, cur) => ({
          ...acc,
          [cur[0]]: cur[1],
        }),
        {},
      )

      this.usePreloadedPrimerSession(this.values.activeOffer)
      await this.push()
    } catch (error) {
      console.error(error)
    }
  },

  isBasicOffer(offer = this.values.activeOffer) {
    return offer?.type?.toLowerCase() === "basic"
  },

  isOfferTrial(offer = this.values.activeOffer) {
    return (
      Array.isArray(offer.prices) &&
      offer.prices.some((price) =>
        [PRICE_TYPES.TRIAL, PRICE_TYPES.CHANCE_TRIAL].includes(price.type),
      )
    )
  },

  getPriceWithoutDiscountKey(isTrialOffer, isExitIntent) {
    if (isTrialOffer && isExitIntent) return "trial_price_without_discount"
    if (isTrialOffer) return "trial_price_without_discount"
    if (isExitIntent) return "total_price_without_discount"

    return "total_price_without_discount"
  },

  getOfferOldPrice(offer = this.values.activeOffer) {
    const isTrialOffer = this.isOfferTrial(offer)
    const { exitIntent } = this.values
    const priceKey = this.getPriceWithoutDiscountKey(isTrialOffer, exitIntent)
    const oldPrice = offer.customData[priceKey]

    return this.getFormattedPrice(oldPrice)
  },

  getPriceType(offer = this.values.activeOffer) {
    const isTrialOffer = this.isOfferTrial(offer)
    const { exitIntent } = this.values

    if (!this.isBasicOffer(offer)) {
      return PRICE_TYPES.STANDARD
    }

    if (isTrialOffer && exitIntent) return PRICE_TYPES.CHANCE_TRIAL
    if (isTrialOffer) return PRICE_TYPES.TRIAL
    if (exitIntent) return PRICE_TYPES.CHANCE

    return PRICE_TYPES.STANDARD
  },

  getFormattedPrice(amount = "0", symbol = this.values.activeOffer.customData.symbol) {
    return `${symbol}${amount}`
  },

  getPriceProperty(priceType, property, offer = this.values.activeOffer) {
    return offer.prices?.find((price) => price.type === priceType)?.[property] || null
  },

  getFormattedTotalPrice(offer = this.values.activeOffer) {
    const { total_price = "0" } = offer.customData
    const isBasicOffer = this.isBasicOffer(offer)
    const priceType = isBasicOffer ? this.getPriceType(offer) : PRICE_TYPES.STANDARD
    const price = this.getPriceProperty(priceType, "amount", offer) || total_price

    return this.getFormattedPrice(price)
  },

  hasExitIntentEligiblePrice(offer = this.values.activeOffer) {
    return (
      offer?.prices?.some((price) =>
        [PRICE_TYPES.CHANCE_TRIAL, PRICE_TYPES.CHANCE].includes(price.type),
      ) && this.isBasicOffer(offer)
    )
  },

  shouldShowExitIntentPopup() {
    const { exitIntent, showDiscountModal, activeOffer } = this.values
    const { chance_discount, trial_chance_price_discount } = activeOffer.customData

    // Exit if popup should not be shown
    if (exitIntent || showDiscountModal || !this.hasExitIntentEligiblePrice(activeOffer)) {
      return false
    }

    // Exit if basic offer has chance discount, or if it's a trial basic offer with a trial chance discount
    if (
      this.isBasicOffer(activeOffer) &&
      (chance_discount || (this.isOfferTrial(activeOffer) && trial_chance_price_discount))
    ) {
      return true
    }

    return false
  },

  async callPaymentsApi(offer, restrictSaveCard) {
    const url = new URL(pipelineConfig.paymentsApiUrl)
    url.pathname = "/api/v1/payments"

    const originUrl = new URL(window.location.origin.replace("localhost:8080", "lvh.me"))
    originUrl.pathname = pipelineConfig.pathPrefix

    const isBasic = this.isBasicOffer(offer)

    return await fetch(url.toString(), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        brandCode: pipelineConfig.brandCode,
        provider: "primer",
        source: "cppv3",
        customerId: this.values.customerId,
        orderId: this.values.id.toString(),
        orderToken: this.values.token,
        chance: offer.type.toLowerCase() === "basic" ? this.values.exitIntent : undefined,
        originUrl: originUrl.toString(),
        fbp: this.values._fbp,
        fbc: this.values._fbc,
        gaCid: this.values._ga?.match(/[^\.]+\.[^\.]+$/)?.[0],
        gaSessionId: this.getGA4SessionId(),
        utmCampaign: this.values.utm_campaign,
        utmSource: this.values.utm_source,
        utmMedium: this.values.utm_medium,
        utmTerm: this.values.utm_term,
        utmContent: this.values.utm_content,
        fraudNetSessionId: this.values.fraud_net_session_id,
        restrictSaveCard: restrictSaveCard || undefined,
        // TODO: For upsells, the offerPriceId may not always reflect
        // the correct price set in the backend.
        // To ensure reliability, it's only assigned for basic offers.
        ...(isBasic && {
          offerPriceId: this.getPriceProperty(this.getPriceType(this.values.activeOffer), "id"),
        }),
        trackingProperties: {
          fb_ads_id: this.values.fb_ads_id || undefined,
          fb_adset_id: this.values.fb_adset_id || undefined,
        },
        ...(offer.brandOfferId
          ? {
              offerId: offer.brandOfferId,
            }
          : {
              priceProductObjectId: offer.priceProductObjectId,
            }),
      }),
    }).then((data) => data.json())
  },

  async payOrCreateSession(restrictSaveCard) {
    const response = await this.callPaymentsApi(this.values.activeOffer, restrictSaveCard)

    if (response.clientToken) {
      this.values.primerSession = response
    } else {
      this.values.primerSession = null
    }

    return response
  },

  clearPrimerSession() {
    this.values.preloadedPrimerSessions = null
    this.values.primerSession = null
  },

  resetLocalStorage() {
    this.values = initialState()
    this.storeAnalytics()
  },

  buildUrlParamsForThankYou(clientSession) {
    const { trackingEvent } = clientSession
    const params = new URLSearchParams({
      invoice_id: trackingEvent?.messageId,
      invoice_amount: trackingEvent?.amountInEuro,
      ga_transaction_id: trackingEvent?.messageId,
      order_id: trackingEvent ? this.values.id : undefined,
      ga_currency: trackingEvent?.currency,
      ga_value: trackingEvent?.amount,
    })

    if (trackingEvent?.offerType) {
      params.set("offer_type", trackingEvent.offerType)
    }

    return params
  },

  async goToLogin() {
    await this.pull(true)
    const loginUrl = this.values.loginUrl
    if (loginUrl) {
      this.values = initialState()
      window.location = loginUrl || pipelineConfig.loginUrl
    }
  },

  savePurchasedBasicOffer() {
    if (!this.values.purchasedBasicOffer) {
      this.values.purchasedBasicOffer = this.values.activeOffer
    }
  },

  restorePurchasedBasicOffer() {
    if (this.values.purchasedBasicOffer) {
      this.values.activeOffer = this.values.purchasedBasicOffer
      this.values.purchasedBasicOffer = null
    }
  },

  isErrorBannerAllowed() {
    const forbiddenPaths = ["16-create-plan"]
    const pagePaths = pipelineConfig.variants[pipelineConfig.variant].pagePaths
    if (pagePaths) {
      const isForbiddenPath = forbiddenPaths.some((path) =>
        window.location.pathname.includes(pagePaths[path]),
      )
      if (isForbiddenPath) {
        return false
      }
    }

    return true
  },
  // Most of above code from u-series with exit intent modifications
  // Functions from d-series
  getFromLocalStorage(key) {
    const val = getStoreByKeyValue(LOCAL_STORAGE_KEY, key)
    return typeof val === "string" ? val : null
  },

  async identifySegmentUser(segmentUserId, email) {
    if (!segmentUserId) {
      return
    }
    if (window.localStorage.getItem("ajs_user_id") !== segmentUserId) {
      window.localStorage.setItem("ajs_user_id", segmentUserId)
      await window.analytics.identify(segmentUserId, { email })
    }
  },

  formattedTargetWeight() {
    const { targetWeightKg, targetWeightLbs } = this.values

    return this.formatWeight(this.isMetric ? targetWeightKg : targetWeightLbs)
  },

  formattedWeightForChatBubble() {
    const weightMetric = "2"
    const weightImperial = "4"

    return this.formatWeight(this.isMetric ? weightMetric : weightImperial)
  },
}
